<template>
    <div class="requests">
        <headerLayout class="h" :no_bg="true"/>

        <div class="main">
            <div class="head">
                <div class="title">Property Requests</div>
                <div class="btn" @click="pop = true">Filter</div>
            </div>

            <div class="showing" v-if="showing">
                <span> Showing: <b>{{ place }} </b> </span>
                <div class="close" @click="remove_showing()">
                    <close :size="'15px'" :style="{'height': '15px'}"/>
                </div>
            </div>
            
            <div class="loading" v-if="loading">
                <div class="spin"></div>
            </div>

            <div class="grid" v-else>
                <div class="request" v-for="request in requests" :key="request.id">
                    <div class="r_head">
                        <div class="r_title">{{ request.type }}</div>
                        <div class="f">
                            <div class="time">
                                <timeago :date="request.date"/>
                            </div>
                            <div>·</div>
                            <div class="location">{{ request.city + ' ' + request.state }}</div>
                        </div>
                    </div>
                    <div class="r_main">
                        <div class="r_grid">
                            <div class="item">
                                <div class="l">Category</div>
                                <div class="v border_right">{{ request.category }}</div>
                            </div>
                            <div class="item">
                                <div class="l">Min Price</div>
                                <div class="v border_right">{{ request.min_price }}</div>
                            </div>
                            <div class="item">
                                <div class="l">Max Price</div>
                                <div class="v">{{ request.max_price }}</div>
                            </div>
                        </div>
                        
                        <router-link to="/dashboard/agent/be">
                            <div class="btn">Post Ad</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="popup" v-if="pop">
            <div class="dark2" @click="pop = false"></div>
            <location @close="pop = false" @save="set_location($event)"/>
        </div>

        <footerLayout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'
import location from '../components/location.vue'

import close from '../icons/close.vue'
import timeago from '../components/time.vue'

import footerLayout from '../components/footer.vue'
export default {
    components: {
        headerLayout,
        location,
        close,
        timeago,
        footerLayout
    },
    data() {
        return {
            loading: true,

            all: [],
            requests: [],
            pop: false,

            place: '',

            showing: false
        }
    },
    mounted() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        this.$http.post('/property_requests')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.loading = false

                this.all = r.requests
                this.requests = r.requests
            }
        })
    },
    methods: {
        set_location(e) {

            this.requests = []

            for(let x of this.all) {
                if (x.city == e.l.city && x.state == e.l.state && x.country == e.l.country) {
                    this.requests.push(x)
                }
            }

            this.place = `${e.l.city + ' ' + e.l.state + ', ' + e.l.country}`
            this.showing = true

            this.pop = false
        },
        remove_showing() {
            this.showing = false
            this.requests = this.all
        }
    }
}
</script>

<style scoped>
    .requests {
        /* background-color: #edeef0; */
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .main {
        margin: 0 auto;
        margin-top: 75px;
        max-width: 1000px;
        padding: 100px 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: 700;
        font-size: 20px;
    }
    .btn {
        background-color: var(--trans);
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        padding: 7px 20px;
        text-align: center;
        font-weight: 500;
    }

    .showing {
        background-color: #edeef0;
        padding: 20px;
        border-radius: 5px;
        margin-top: 25px;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        font-size: 14px;
    }

    
    .spin {
        margin: 70px auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid var(--main);
        border-top-color: transparent;
        animation: rotate 1s infinite linear;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 30px;
        margin-top: 50px;
    }
    .request {
        /* border: 1px solid #ddd; */
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 5px;
        font-size: 14px;
    }
    .r_head {
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }
    .r_title {
        font-weight: 700;
    }
    .f {
        display: flex;
        gap: 10px;
    }

    .r_main {
        padding: 15px;
    }
    .r_grid {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        text-align: center;
        margin-bottom: 20px;
    }
    .l {
        font-size: 12px;
    }
    .v {
        font-size: 12px;
        margin-top: 10px;
        font-weight: 800;
        width: 100%;
    }
    .border_right {
        border-right: 1px solid #ddd;
    }
    
    
      
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 5;
    }
    .dark2 {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }



    @media screen and (max-width: 800px) {
        .main {
            padding: 30px;
        }
        .grid {
            margin-top: 35px;
        }
    }
</style>